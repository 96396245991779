(function($) {
    "use strict";
	
	
    /* slim menu */
	
    if (('.slimmenu').length > 0) {
        $('.slimmenu').slimmenu({
            resizeWidth: '991',
            collapserTitle: 'Main Menu',
            animSpeed: 'medium',
            indentChildren: true,
        });


    }

    /* sticky header */

    function fixed_top_menu() {

        var windows = $(window);

        windows.on("scroll", function() {

            var header_height = $(".header-area").height();

            var scrollTop = windows.scrollTop();

            if (scrollTop > header_height) {

                $(".header-area").addClass("sticky");

            } else {

                $(".header-area").removeClass("sticky");

            }
        });

    }

    fixed_top_menu();

    /* Testimonial-Slider */
	
    if (('.testimonial-slider').length > 0) {
        $(".testimonial-slider").owlCarousel({
            autoplay: true,
            dots: false,
            loop: true,
            margin: 30,
            lazyLoad: true,
            center: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1200: {
                    items: 3
                }
            }
        });
    }
	
	
    /*  Custom multi js*/
	
    if ((".custom-multi-select").length > 0) {
        $('.custom-multi-select').select2();

    }

    /*  magnificPopup */

    $('.video-play').magnificPopup({
        type: 'iframe'
    });
	
	/*  Preloader js*/
	
    $(window).on('load', function() {

        var preLoder = $("#preloader");
        preLoder.fadeOut(1000);

    });
	
    /* highlight js */
	
    if (('pre code').length > 0) {
        document.querySelectorAll('pre code').forEach((block) => {
            hljs.highlightBlock(block);
        });
    }	
	
    /*  AOS animation*/

    AOS.init({
        once: true,

    });
	

}(jQuery));